import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "address", "city", "postalCode" ]

  connect() {
    if (typeof google === 'object' && typeof google.maps === 'object') {
      this.initializeAutocomplete();
    } else {
      document.addEventListener('google-maps-callback', this.initializeAutocomplete.bind(this));
    }
  }

  initializeAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget);
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();
    if (!place.geometry) {
      console.log("No details available for input: '" + place.name + "'");
      return;
    }

    let city = '';
    let postalCode = '';

    place.address_components.forEach(component => {
      const addressType = component.types[0];
      const val = component.long_name;

      if (addressType === 'locality') {
        city = val;
      } else if (addressType === 'postal_code') {
        postalCode = val;
      }
    });

    if (city) {
      this.cityTarget.value = city;
    }

    if (postalCode) {
      this.postalCodeTarget.value = postalCode;
    }
  }
}
