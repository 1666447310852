const selectBox = () => {
    const boxBtn = document.querySelectorAll(".category-choice")
    boxBtn.forEach((box) => {
      box.addEventListener("click", () => {
        box.classList.toggle("active");   
      })
    })
  }

export { selectBox }
