const dynamicCityDropdown = () => {
    const countrySelect = document.getElementById("photographer_country");
    const citySelect = document.getElementById("photographer_city");
    const cityContainer = document.getElementById("city_list")
    const countrySelectMobile = document.getElementById("photographer_country_mobile");
    const citySelectMobile = document.getElementById("photographer_city_mobile");
    if (countrySelect) {
        countrySelect.addEventListener("change", (event) => {
            citySelect.innerHTML = "";      
            const cityList = JSON.parse(cityContainer.dataset.list);
            cityList.forEach((city) => { 
                if (city.country_id == event.currentTarget.value) {
                    const cityOption = `<option value="${city.name}">${city.name}</option>`;
                    citySelect.insertAdjacentHTML("beforeend", cityOption); 
                }
            })
           document.getElementById("cityselection_input").classList.remove("hidden");
        })
    } 
    if (countrySelectMobile) {
        countrySelectMobile.addEventListener("change", (event) => {
            citySelectMobile.innerHTML = "";      
            const cityList = JSON.parse(cityContainer.dataset.list);
            cityList.forEach((city) => { 
                if (city.country_id == event.currentTarget.value) {
                    const cityOption = `<option value="${city.name}">${city.name}</option>`;
                    citySelectMobile.insertAdjacentHTML("beforeend", cityOption); 
                }
            })
            document.getElementById("cityselection_input_mobile").classList.remove("hidden");
        })
    } 
}  

export { dynamicCityDropdown };