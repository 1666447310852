import "bootstrap";
import "../component/check_box";
import "../plugins/flatpickr";
import "chartkick";
import "chart.js";


import { selectBox } from '../component/check_box'
selectBox();

import 'select2/dist/css/select2.css';
import { initSelect2 } from '../component/init_select2';
import { dynamicCityDropdown } from '../component/dynamic_city_dropdown';

// phone yarn
import intlTelInput from 'intl-tel-input';


initSelect2();
dynamicCityDropdown();
import "controllers"

require("trix")
require("@rails/actiontext")
